import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
// import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import TwilioDashboard from "./pages/TwilioDashboard";
import APIInfo from "./pages/APIInfo";
import "./App.css";

const App = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path='/' exact element={<APIInfo />} />
                    <Route path='/login' exact element={<Login />} />
                    <Route path='/dashboard' exact element={<Dashboard />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

// <BrowserRouter>
//     <Routes>
//         <Route path='/' exact element={<APIInfo />} />
//         <Route path='/login' exact element={<Login />} />
//         <Route path='/dashboard' exact element={<Dashboard />} />
//         <Route path='/twiliodashboard' exact element={<TwilioDashboard />} />;
//     </Routes>
// </BrowserRouter>;

// Registration is disabled
// <Route path='/register' exact element={<Register />} />;

export default App;
