import { useEffect, useState } from "react";
import { Checkbox } from "semantic-ui-react";

const Dashboard = () => {
    return (
        <>
            <SMSList />
        </>
    );
};

const SMSList = () => {
    const [smsRecords, setSMSRecords] = useState([]);

    const fetchSMS = async () => {
        let storedToken = localStorage.getItem("token");
        if (!storedToken) {
            alert("Tienes que identificarte para acceder al Dashboard");
            window.location.href = "/login";
        }
        const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/sms`, {
            headers: {
                "x-access-token": storedToken,
            },
        });
        const retrievedSMS = await res.json();

        if (retrievedSMS.status === "error") {
            let alertText;
            switch (retrievedSMS.errorType) {
                case "mongodb-error":
                    alertText = `Error de conexión con MongoDB. \nMensaje de error: ${retrievedSMS.errorMessage}`;
                    break;
                case "token-error":
                    alertText = `Error de token de acceso no válido. \nMensaje de error: ${retrievedSMS.errorMessage}`;
                    break;
                default:
                    alertText = `Error desconocido. \nMensaje de error: ${retrievedSMS.errorMessage}`;
            }
            alert(alertText);
        } else {
            setSMSRecords(retrievedSMS);
        }
    };

    const smsEntryList = smsRecords.map(smsRecord => (
        <SMSItem key={smsRecord._id} smsItem={smsRecord} fetchSMS={fetchSMS} />
    ));

    useEffect(() => {
        fetchSMS();
    }, []);

    return (
        <div className='ui card fluid'>
            <div className='content'>
                <div className='header'>Panel de Control - Twilio</div>
            </div>
            <div className='content'>
                <p>Conectado como {localStorage.getItem("email")}.</p>
            </div>
            <div className='content'>
                <div className='ui relaxed divided list'>{smsEntryList}</div>
            </div>
        </div>
    );
};

const SMSItem = props => {
    const { send, text, friendlyId, mobile, _id } = props.smsItem;

    const toggleSend = async () => {
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/sms/${_id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": localStorage.getItem("token"),
            },
            body: JSON.stringify({ friendlyId, send: !send, text, mobile }),
        });

        props.fetchSMS();
    };

    return (
        <>
            <br />
            {send ? (
                <Checkbox
                    toggle
                    defaultChecked
                    label={text + " *** ON ***"}
                    className='left floated'
                    onClick={toggleSend}
                />
            ) : (
                <Checkbox
                    toggle
                    label={text + " (OFF)"}
                    className='left floated'
                    onClick={toggleSend}
                />
            )}
            <br />
        </>
    );
};

export default Dashboard;
