import { useState, useEffect } from "react";

const APIInfo = () => {
    const [apiInfo, setApiInfo] = useState("");

    useEffect(() => {
        const fetchInfo = async () => {
            try {
                const res = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/`
                );
                const retrievedInfo = await res.json();
                setApiInfo(retrievedInfo.message);
            } catch (error) {
                console.log("error: ", error.message);
            }
        };

        // useEffect(() => getInfo());
        fetchInfo();
    }, []);

    return (
        <>
            <div>{apiInfo}</div>
            <br />
            <button
                onClick={() => {
                    window.location.href = "/login";
                }}
            >
                Login
            </button>
            <br />
            <br />
            <button
                onClick={() => {
                    window.location.href = "/dashboard";
                }}
            >
                Dashboard
            </button>
        </>
    );
};

export default APIInfo;
