import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(<App />, document.getElementById("root"));

// React.StrictMode removed to get rid of the following warning:
// Warning: findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of RefFindNode which is inside StrictMode. Instead, add a ref directly to the element you want to reference. Learn more about using refs safely here: https://reactjs.org/link/strict-mode-find-node
//     at input
//     at RefFindNode (http://localhost:3000/static/js/bundle.js:1007:35)
//     at Ref (http://localhost:3000/static/js/bundle.js:941:24)
//     at div
//     at Checkbox (http://localhost:3000/static/js/bundle.js:44765:29)
//     at SMSItem (http://localhost:3000/static/js/bundle.js:440:13)
//     at div
//     at div
//     at div
//     at SMSList (http://localhost:3000/static/js/bundle.js:357:86)
//     at Dashboard
//     at Routes (http://localhost:3000/static/js/bundle.js:38176:5)
//     at Router (http://localhost:3000/static/js/bundle.js:38109:15)
//     at BrowserRouter (http://localhost:3000/static/js/bundle.js:37589:5)
//     at div
//     at App
//
// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
