import { useState } from "react";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const loginUser = async event => {
        event.preventDefault();

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/login`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email,
                        password,
                    }),
                }
            );
            const data = await response.json();
            console.log("data (received from backend):");
            console.log(data);

            if (data.token) {
                localStorage.setItem("token", data.token);
                localStorage.setItem("email", email);
                console.log("data.token");
                console.log(data.token);
                // alert("Conectado con éxito");
                window.location.href = "/dashboard";
            } else {
                alert(
                    "Por favor, comprueba los datos introducidos (email y contraseña)"
                );
            }
        } catch (error) {
            console.log("Error in Login.js > loginUser: ");
            console.log(error.message);
        }
    };

    return (
        <div>
            <h1>Login</h1>
            <form onSubmit={loginUser}>
                <input
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    type='text'
                    placeholder='Email'
                />
                <br />
                <input
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    type='text'
                    placeholder='Password'
                />
                <br />
                <input type='submit' value='Login' />
            </form>
        </div>
    );
};

export default Login;
